<template>
  <div class="admin-edit-announcement-template">
    <loading-screen
      :is-loading="isSaving || isDeleting || isLoading"
    ></loading-screen>

    <div class="loaded" v-if="!isLoading && quickMessage">
      <div class="page-header">
        <h1 class="page-title">Edit Announcement Template</h1>
        <div class="actions">
          <a-button
            class="button-margin-left btn-rounded"
            icon="arrow-left"
            size="large"
            type="primary"
            @click.prevent="goBack"
            >Back</a-button
          >

          <a-popconfirm
            title="Are you sure?"
            ok-text="Yes"
            cancel-text="No"
            @confirm="attemptDelete"
          >
            <a-button
              class="button-margin-left btn-rounded"
              icon="delete"
              size="large"
              type="danger"
              >Delete</a-button
            >
          </a-popconfirm>
        </div>
      </div>

      <div class="form">
        <a-form-item label="Title">
          <a-input
            size="large"
            v-model="quickMessage.displayName"
          ></a-input>
        </a-form-item>

        <a-form-item label="Description">
          <a-textarea
            :rows="6"
            size="large"
            v-model="quickMessage.body"
          ></a-textarea>
        </a-form-item>

        <a-form-item label="Visibility">
          <a-radio-group v-model="quickMessage.scope">
            <a-radio :value="1">Shown to organisation</a-radio>
            <!-- <a-radio :value="2">Shown to whole tenant</a-radio> -->
            <a-radio :value="3">Hidden from lists</a-radio>
          </a-radio-group>
        </a-form-item>
      </div>

      <a-button
        @click.prevent="save"
        class="btn-rounded save-button"
        size="large"
        type="primary"
        >Save</a-button
      >
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import LoadingScreen from "../../../../components/LoadingScreen.vue";
import quickMessages from "../../../../api/quick-messages";
export default {
  data() {
    return {
      isLoading: false,
      isSaving: false,
      isDeleting: false,
      quickMessage: null,
    };
  },

  components: { LoadingScreen },

  computed: {
    ...mapGetters("admin", {
      tenantId: "tenantId",
      selectedOrganisation: "selectedOrganisation",
    }),
  },

  methods: {
    goBack() {
      this.$router.push("/admin/settings/announcement-templates");
    },

    attemptDelete() {
      let vm = this;
      vm.isDeleting = true;
      quickMessages
        .deleteQuickMessage(this.tenantId, this.quickMessage.id)
        .then(() => {
          vm.isDeleting = false;
          vm.$message.success("Announcement template deleted successfully");
          vm.$router.push("/admin/settings/announcement-templates");
        })
        .catch((e) => {
          console.log(e);
          vm.$message.error("Error deleting announcement template");
          vm.isDeleting = false;
        });
    },

    loadQuickMessage() {
      let vm = this;
      vm.isLoading = true;
      quickMessages
        .getQuickMessage(vm.tenantId, vm.$route.params.id)
        .then((r) => {
          vm.isLoading = false;
          vm.quickMessage = r.data;
        })
        .catch((e) => {
          console.log(e);
          vm.isLoading = false;
          vm.$message.error("Error loading announcement template");
          vm.$router.push("/admin/settings/announcement-templates");
        });
    },

    getValidationErrors() {
      let errors = [];
      if (this.quickMessage.displayName.trim().length == 0) {
        errors.push("Please provide a title");
      }
      if (this.quickMessage.body.trim().length == 0) {
        errors.push("Please provide a description");
      }
      return errors;
    },

    async save() {
      let vm = this;

      // Validation
      let validationErrors = this.getValidationErrors();
      if (validationErrors.length) {
        this.$message.error(validationErrors[0]);
        return false;
      }

      vm.isSaving = true;

      try {
        await quickMessages.updateQuickMessage(
          this.tenantId,
          this.quickMessage
        );

        vm.isSaving = false;
        vm.$message.success("Announcement template updated successfully");
        vm.$router.push("/admin/settings/announcement-templates");
      } catch (e) {
        console.log(e);
        vm.isSaving = false;
        vm.$message.error("Error updating announcement template");
      }
    },
  },

  created() {
    if (!this.selectedOrganisation) {
      this.$message.info("Please select an organisation");
      this.$router.push("/admin");
    } else {
      this.loadQuickMessage();
    }
  },
};
</script>

<style scoped lang="scss">
.admin-edit-announcement-template {
  .form {
    background: #fff;
    padding: 20px;
    border-radius: 6px;
    font-weight: 500;
    border: 1px solid #fff;
  }

  .button-margin-left {
    margin-left: 10px;
  }

  .save-button {
    margin-top: 20px;
  }
}
</style>